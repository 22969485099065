/* =Strip basics
==========================================*/
.strip {
	padding: $strip-padding / 2 0;

	.wrap {
		padding-left: $strip-padding / 2;
		padding-right: $strip-padding / 2;
	}

}



/* =Heading
==========================================*/
.heading {
	text-align: left;
}

h1 {
	font-size: 3.3em;
	margin-bottom: 0;
	margin-top: .4em;
}



/* =Layout
==========================================*/
.layout-grid {
	display: grid;
	grid-gap: $strip-padding;
	grid-template-columns: 1fr 272px;
	margin: 1em 0 0;
}

.content {
	grid-column-start: 2;
	grid-column-end: 3;
	margin-top: 1.6em;
	text-align: left;
}

.form-wrap {
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 2;
	margin: 0;
}