/* =Base
==========================================*/
html {
	box-sizing: border-box;
	height: 100%;
	// NOTE - This is dependant on how the theme is to work. Browser support not perfect.
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	background-color: $brand-primary;
	background-image: url('../images/svgs/icon-vote-print.svg');
	background-position: right $strip-padding bottom;
	background-repeat: no-repeat;
	color: $white;
}

.site-wrap {
	display: block;
}

.heading {
	text-align: center;
}



/* =Strip basics
==========================================*/
.strip {
	padding: $strip-padding 0;
}



/* =Logo Masthead
==========================================*/
.logo-mast {
	display: flex;
	justify-content: space-between;
}

.logo {
	display: block;
}



/* =Grid Layout
==========================================*/
.layout-grid {
	display: grid;
}

.content {
	display: block;
	text-align: center;

	> p {
		font-size: .875em;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

	}

}

.form-wrap {
	margin: 2em 0 0;
}



@media only screen and (min-width: 50em) {

	@import '800';

}



