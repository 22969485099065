%btn {
	background-color: $brand-primary;
	border: 0;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-size: 1em;
	padding: .6em;
	position: relative;
	text-decoration: none;

	transition: background-color $transition $easing,
				color $transition $easing;

	&:hover,
	&:focus {
		background-color: $brand-primary;
		color: $white;
		text-decoration: none;
	}

	&:active {

	}

}

.button-wrap {

	a,
	a:visited {
		@extend %btn;
	}

}
