/* =Colour Variables
==========================================*/
$alert-yellow:			#ebe16f;
$alert-red:				#fbe3e4;
$alert-green:			#e6efc2;
$alert-blue:			#d5edf8;

$black:					#000;
$white:					#fff;

$text-color:			#5c6b80;

$brand-primary:			#662483;
$brand-secondary:		#953D9F;
$brand-tertiary:		#eceee5;

$link-color:        	$brand-primary;
$link-hover:        	darken($link-color, 9%);


/* =Social colours
==========================================*/
$social-facebook:		#4d70a8;
$social-twitter:		#01baf2;
$social-google:			#e3401d;
$social-linkedin:		#1687b1;


/* =Wrap Width
==========================================*/
$wrap-restrict:			75.000em; // 1200px
$wrap-break:			$wrap-restrict + 3em;


/* =Strip Padding
==========================================*/
$strip-padding:			3.125em;


/* =Site Transitions
==========================================*/
$transition:			.25s;
$easing: 				ease-in-out;


/* =Radius
==========================================*/
$radius:				.4em;


/* =Font weights in use from Typekit or Google Fonts
==========================================*/
$light:					100;
$regular:				400;
$semi: 					500;
$italic:				400;
$bold:					700;


/* =Theme url
==========================================*/
$url:					'/library/';


/* =Font Families
==========================================*/
@font-face {
	font-family: 'ManifoldCF-DemiBold';
	src: url('#{$url}/fonts/manifold-cf-demi-bold/manifold-cf-demi-bold.eot');
	src: url('#{$url}/fonts/manifold-cf-demi-bold/manifold-cf-demi-bold.eot?#iefix') format('embedded-opentype'),
             url('#{$url}/fonts/manifold-cf-demi-bold/manifold-cf-demi-bold.woff2') format('woff2'),
	     url('#{$url}/fonts/manifold-cf-demi-bold/manifold-cf-demi-bold.woff') format('woff'),
	     url('#{$url}/fonts/manifold-cf-demi-bold/manifold-cf-demi-bold.ttf') format('truetype'),
	     url('#{$url}/fonts/manifold-cf-demi-bold/manifold-cf-demi-bold.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ManifoldCF-Bold';
	src: url('#{$url}/fonts/manifold-cf-bold/manifold-cf-bold.eot');
	src: url('#{$url}/fonts/manifold-cf-bold/manifold-cf-bold.eot?#iefix') format('embedded-opentype'),
             url('#{$url}/fonts/manifold-cf-bold/manifold-cf-bold.woff2') format('woff2'),
	     url('#{$url}/fonts/manifold-cf-bold/manifold-cf-bold.woff') format('woff'),
	     url('#{$url}/fonts/manifold-cf-bold/manifold-cf-bold.ttf') format('truetype'),
	     url('#{$url}/fonts/manifold-cf-bold/manifold-cf-bold.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
}

// font stacks
$sans-serif:        "Helvetica Neue", Helvetica, Arial, sans-serif;
$serif:             "Georgia", Cambria, Times New Roman, Times, serif;

$fancy-sans:		'ManifoldCF-Bold', $sans-serif;
$fancy-sans-light:	'ManifoldCF-DemiBold', $fancy-sans, $sans-serif;
$fancy-serif:		$serif;
