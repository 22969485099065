.wrap {
	margin: 0 auto;
	padding: 0 $strip-padding;

	@media only screen and (min-width: $wrap-break) {

		max-width: $wrap-restrict;
		padding-left: 0;
		padding-right: 0;

	}

}
